<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="edithour">
		<div class="edithour-apply">
			<div class="edithour-apply-item">
				<div class="apply-item-left">
					<img class="apply-item-avatar" src="@/assets/images/mobile/common/avatar.png" alt="">
					<div class="apply-item-user">
						<div class="item-user-inner">
							<span class="item-user-name">{{manhourDetail.name}}</span>
							<span class="item-user-num">{{manhourDetail.studentNumber}}</span>
						</div>
						<span class="item-user-class">{{manhourDetail.college}}</span>
					</div>
				</div>
				<div class="apply-item-right">
					<span class="apply-item-hour">工时：{{totalWorkHours}} h</span>
					<span class="apply-item-edit"></span>
				</div>
			</div>
		</div>
		<div>
			<div class="edithours-box" v-for="(item,index) in manhourDetail.workingHours" :key="index">
				<span class="title" v-if="postType=='S_FIX'">{{item.workingHourTime}}月工时</span>
				<span class="title" v-else>单次工时</span>
				<div class="edithour-ipt" :class="{'gray':item.state=='CONFIRM'||item.state=='VERIFY'||item.state=='SETTLEMENT'}">
					<input v-model="item.workTime" placeholder="请输入新工时" :disabled="item.state=='CONFIRM'||item.state=='VERIFY'||item.state=='SETTLEMENT'" type="text">
					<span>h</span>
				</div>
			</div>
		</div>
		
		
		<div @click="submit" class="edithour-btn" :class="{'edithour-btn-active': totalWorkHours>0}">
			确认
		</div>
	</div>
</template>
<script>
	import {
		manhourDetailMb,
	} from "@/api/mobile/manhour";
	import storage from 'store'
	export default {
		data() {
			return {
				manHourId: "",
				id: "",
				manhourDetail: {},
				manHoursVal: "",
				iptArr: [],
				signUpArr:[],
				postType:""
			}
		},
		mounted() {
			this.id = this.$route.query.id
			this.postType = this.$route.query.postType
			this.manHourId = this.$route.query.manHourId
			if(storage.get("accessManhour")) {
				this.iptArr = storage.get("accessManhour")
			}
			if(storage.get("signUpData")){
				this.signUpArr = storage.get("signUpData")
				this.signUpArr.forEach(el=>{
					if(el.id===this.id){
						this.manhourDetail = el
					}
				})
			}
			// this.getManhourDetail()
		},
		computed:{
			totalWorkHours(){
				if(!this.manhourDetail.workingHours){
					return
				}
				let result = 0
				let arr = this.manhourDetail.workingHours
				for(let i = 0;i<arr.length;i++){
					result += Number(arr[i].workTime)
				}
				this.manhourDetail.workHours = result
				return result
			 }
		},
		methods: {
			// 工时详情
			getManhourDetail() {
				let params = {
					id: this.manHourId
				}
				manhourDetailMb(this.manHourId, params).then((res) => {
					if (res.code == 0) {
						let resManhour = res.data.signUp.filter(item => item.id == this.id)
						// console.log("resManhour", resManhour)
						this.manhourDetail = resManhour[0]
					}
				})
			},
			submit() {
				if(this.totalWorkHours==0) {
					this.$toast("请输入新工时")
					return
				}
				// } else {
				// 	let regx = /^\d*\.{0,1}\d{0,1}$/
				// 	if(!regx.test(this.manHoursVal)) {
				// 		this.$toast("请输入正确工时,支持一位小数")
				// 		return
				// 	}
				// }
				// this.iptArr.push({id: this.manHourId, iptVal: this.manhourDetail.workHours, iptId: this.id })
				// storage.set("accessManhour", this.iptArr)
				this.signUpArr.forEach(item=>{
					if(item.id===this.manhourDetail.id){
						item = this.manhourDetail
					}
				})
				// console.log(this.signUpArr,'signUpArr')
				storage.set("signUpData", this.signUpArr)
				this.$router.go(-1)
			},
		},
	}
</script>
<style lang="scss" scoped>
/deep/input:disabled {
	background:#ffffff!important;
}
	.edithour {
		display: flex;
		flex-direction: column;

		.edithour-apply {
			display: flex;
			flex-direction: column;
			margin: 0.16rem 0;

			.edithour-apply-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				background: #FFFFFF;
				padding: 0.3rem 0.3rem;
				box-sizing: border-box;

				.apply-item-left {
					display: flex;
					align-items: center;

					.apply-item-avatar {
						width: 0.9rem;
						height: 0.9rem;
						border-radius: 50%;
						margin-right: 0.22rem;
					}

					.apply-item-user {
						display: flex;
						flex-direction: column;

						.item-user-inner {
							display: flex;
							align-items: center;

							.item-user-name {
								font-size: 0.32rem;
								font-family: PingFang SC;
								font-weight: bold;
								line-height: 0.44rem;
								color: #303133;
								margin-right: 0.2rem;
							}

							.item-user-num {
								font-size: 0.28rem;
								font-family: PingFang SC;
								font-weight: 400;
								line-height: 0.44rem;
								color: #606266;
							}
						}

						.item-user-class {
							font-size: 0.24rem;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 0.34rem;
							color: #909399;
							margin-top: 0.1rem;
						}
					}
				}

				.apply-item-right {
					display: flex;
					flex-direction: column;
					align-items: flex-end;

					.apply-item-hour {
						font-size: 0.28rem;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 0.4rem;
						color: #606266;
					}

					.apply-item-edit {
						width: 0.28rem;
						height: 0.28rem;
						margin-top: 0.14rem;
					}
				}
			}
		}
		.gray{
			color:#999999!important;
		}
		.edithour-ipt {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			height: 0.96rem;
			box-sizing: border-box;
			// position: relative;
			color:#333333;
			input {
				flex: 1;
				height: 0.8rem;
				outline: none;
				border: 0;
				font-size: 0.28rem;
				font-family: PingFang SC;
				font-weight: 400;
				padding-left: 0.28rem;
				text-align: right;
			}

			span {
				font-size: 0.28rem;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 0.96rem;
				color: #606266;
				display: inline-block;
				margin-left:0.16rem;
				// position: absolute;
				// right: 0.28rem;

			}
		}
		.edithours-box{
			display: flex;
			justify-content: space-between;
			align-items: center;
			background:#ffffff;
			padding:0 0.28rem;
			margin-bottom:0.08rem;
			.title{
				color:#606266;
				font-size:0.28rem;
			}
		}
		.edithour-btn {
			width: 6.94rem;
			height: 0.8rem;
			background: rgba(67, 146, 250, 0.4);
			border-radius: 0.08rem;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0.64rem auto 0rem auto;
			font-size: 0.28rem;
			font-family: PingFang SC;
			font-weight: bold;
			color: #FFFFFF;
		}

		.edithour-btn-active {
			background: #4392FA;
		}
	}
</style>
